var generic = generic || {};
$(function(){
  var path = window.location.pathname;
  var pathArray = path.split('/');
  var cookieLocale = generic.cookie('LOCALE');
  if (cookieLocale !== undefined && cookieLocale.toLowerCase() == 'fr_ch' && pathArray[1].toLowerCase() != 'fr-e-swi' && path.indexOf(".tmpl") == -1 && path.indexOf(".tmpl") == -1) {
        var full_url = window.location.href;
        var appends = ""
        //BugId:254463
	      if(full_url.indexOf("#") > 0)
		  {
			 var full_url_parts = full_url.split("#"); //BugId:254463
			 appends = "#" + full_url_parts[1];
		  }else if(full_url.indexOf("?") > 0){
			 var full_url_parts = full_url.split("?");
			 appends = "?" + full_url_parts[1];
		  }
        window.location.href = window.location.protocol + '//' + window.location.host + '/fr-e-swi' + window.location.pathname + appends;
        $("a[data-mp-lang='de']").removeClass("language-select__link--selected");
        $("a[data-mp-lang='fr']").addClass('language-select__link--selected');
  } else if (pathArray[1].toLowerCase() === 'fr-e-swi' && path.indexOf('.tmpl') === -1) {
    var lang = pathArray[1].toLowerCase() === 'fr-e-swi' ? 'fr_CH' : 'de_CH';
    document.cookie = 'LOCALE=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    generic.cookie('LOCALE', lang, {path: '/'});
  } else {
    var language_code = $('.language-select__item .js-switch-lang-link').attr('data-mp-lang');
    var selectedlang = language_code === 'de' ? 'de_CH' : 'fr_CH';
    document.cookie = 'LOCALE=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    generic.cookie('LOCALE', selectedlang, {path: '/'});
  }
  $('.js-switch-lang-link').on('click', function(event) {
      event.preventDefault();
      var lang = ($(this).attr('data-mp-lang') == 'de')?'de_CH':'fr_CH';
      return switchLanguageCH(lang);
  });
  $('.js-lang-link1').on('click', function(event) {
      event.preventDefault();
      var lang = 'de_CH';
      return switchLanguageCH(lang);
  });
  $('.js-lang-link2').on('click', function(event) {
      event.preventDefault();
      var lang = 'fr_CH';
      return switchLanguageCH(lang);
  });

  function switchLanguageCH(lang) {
      var domains = { 'de_CH' : '/', 'fr_CH' : '/fr-e-swi/' };
    var pathArrayCheck = pathArray[1] === '' ? '/' : pathArray[1].replace(/^/, '/').replace(/$/, '/');
    if (lang !== generic.cookie('LOCALE') || pathArrayCheck !== domains[lang]) {
          var path = window.location.pathname.replace(/\/fr\-e-SWI/i, "");
          document.cookie = 'LOCALE=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          generic.cookie('LOCALE', lang, {path : '/'});

          if (path.indexOf(".tmpl") > 0 || path.indexOf(".tmpl") > 0) {
            domains[lang] = '';
          }
          try {
            if (Drupal.settings.elc_common[lang + "_url"] != undefined && path != "/") {
              path = Drupal.settings.elc_common[lang + "_url"];
            }
          }
          catch (error) {}

          if (path == "/") {
            path = "";
          }
          var site_path = window.location.host + domains[lang] + path;
          site_path = site_path.replace(/\/+/g, '/');//BugId:229567 - To remove the extra slashes in the path
          var url = window.location.protocol + '//' + site_path;
          var full_url = window.location.href;
		  var appends = ""
	      if(full_url.indexOf("#") > 0)
		  {
			 var full_url_parts = full_url.split("#"); //BugId:254463
			 appends = "#" + full_url_parts[1];
		  }else if(full_url.indexOf("?") > 0){
			 var full_url_parts = full_url.split("?");
			 appends = "?" + full_url_parts[1];
		  }
           window.location.href = url + appends;
      }
  }

});
